<script lang="ts" setup>
import type { Notification } from '~/ts/interfaces/Notification';
import type { StrapiData } from '~/ts/interfaces/strapi/StrapiData';

const config = useRuntimeConfig()

const notification = ref<StrapiData<Notification>>()

const { data: notificationResponse } = await useFetch(`${config.public.apiUrl}/api/notification`)
notification.value = notificationResponse.value as StrapiData<Notification>

</script>

<template>
    <div v-if="notification?.data?.enabled" class="notification" :class="notification?.data?.level">
        <span class="notification__title">{{ notification?.data?.title }}</span>
        <UtilMarkdown class="notification__content" :source="notification?.data?.content"></UtilMarkdown>
    </div>
</template>

<style lang="scss" scoped>
.notification {
    padding: 1rem;
    transition: all 0.3s ease-in-out;
    color: white;
    &.info {
        background-color: $info;
        border-left: 7px solid darken($color: $info, $amount: 10%);
    }
    &.warn {
        background-color: $warning;
        border-left: 5px solid darken($color: $warning, $amount: 10%);
        color: black;
    }
    &.error {
        background-color: $danger;
        border-left: 5px solid darken($color: $danger, $amount: 10%);
    }
    &.success {
        background-color: $success;
        border-left: 5px solid darken($color: $success, $amount: 10%);
    }
    &__title {
        font-weight: bold;
        font-size: 1.2rem;
    }
    &__content {
        margin-top: 0.5rem;
    }
}
</style>
